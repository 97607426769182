import isNumeric from 'fast-isnumeric'
import { currencySymbolToCode, isCurrencySymbol } from './currencyFormatters'

function formatNumberToLocale (number, opts = {}, locale = 'fi-FI') {
  if (opts.locale) {
    locale = opts.locale
  }

  if (locale === 'sv') {
    locale = 'sv-SE'
  }

  if (!isNumeric(number)) {
    return '-'
  }

  const minimumFractionDigits = opts.fractionDigits
    ? opts.fractionDigits
    : number % 1 ? 2 : 0

  if (Intl && Intl.NumberFormat()) {
    opts = Object.assign({ minimumFractionDigits }, opts)

    return new Intl.NumberFormat(locale, opts).format(number)
  }

  return spaceBeforeThousandth(number)
}

function spaceBeforeThousandth (amount) {
  const isFloat = Number(amount) === amount && amount % 1 !== 0
  amount = amount.toLocaleString().replace('.', ',')
  if (!isFloat) {
    amount = amount.replace(',', ' ')
    return amount
  }
  const thousandLocations = []
  for (let i = 0; i < amount.length; i++) {
    if (amount[i] === ',') {
      thousandLocations.push(i)
    }
  }
  // if last comma is for floating point, ignore it by popping from the list
  if (amount.slice(-3).indexOf(',') > -1) {
    thousandLocations.pop()
  }
  thousandLocations.forEach(index => {
    amount = amount.substr(0, index) + ' ' + amount.substr(index + 1)
  })
  return amount
}

function formatInterest (interest, locale = null, addPercent = false) {
  const number = formatNumberToLocale(interest, { fractionDigits: 1, maximumFractionDigits: 3, locale })

  return number + ((number !== '-' && addPercent && ' %') || '')
}

function formatMoneyWithCurrency (nr, currency, locale, options = {}) {
  if (!locale) {
    throw new Error('Cannot format money, locale is missing')
  }

  if (nr === 0) {
    if (options.zeroAs) {
      return options.zeroAs
    }

    options.fractionDigits = 0
    options.maximumFractionDigits = 0
  }

  if (nr === null) {
    return options.nullAs ?? '...'
  }

  const fractionDigits = (options.fractionDigits || options.fractionDigits === 0)
    ? options.fractionDigits
    : 2
  const maximumFractionDigits = (options.maximumFractionDigits || options.maximumFractionDigits === 0)
    ? options.maximumFractionDigits
    : 2

  const currencyValue = isCurrencySymbol(currency) ? currencySymbolToCode(currency) : currency
  const style = currency ? 'currency' : undefined
  const opts = {
    style,
    fractionDigits,
    maximumFractionDigits,
    currency: currencyValue === null ? undefined : currencyValue,
    locale
  }

  return formatNumberToLocale(nr, opts)
}

function maxTwoDecimals (inputStr) {
  let dottedStr = (inputStr + '').replace(/,/g, '.').replace(/[^0-9.]+/g, '').replace(/^00+/, '0')
  dottedStr = dottedStr === '.' ? '' : dottedStr
  const parts = dottedStr && dottedStr.split && dottedStr.split('.')
  const hasMoreThanTwoDecimals = parts && parts.length > 1 && (parts[1].length > 2 || parts.length > 2)

  return hasMoreThanTwoDecimals
    ? dottedStr.match(/^-*\d+(?:\.\d{0,2})?/)[0]
    : dottedStr
}

function getDecimalSeparator (locale) {
  if (locale) {
    const numberWithDecimalSeparator = 1.1
    const formatter = Intl.NumberFormat(locale)

    if (formatter && formatter.formatToParts) {
      return formatter
        .formatToParts(numberWithDecimalSeparator)
        .find(part => part.type === 'decimal')
        .value
    }
  }

  return '.'
}

function isNotZero (value) {
  const numericValue = parseFloat(value)

  return !isNaN(numericValue) && numericValue !== 0
}

export {
  formatNumberToLocale,
  formatMoneyWithCurrency,
  formatInterest,
  maxTwoDecimals,
  getDecimalSeparator,
  isNotZero
}
