<template>
    <layout-with-menu :loading="isLoading">
      <hero-header :title="translations.title" />
      <div class="my-transactions__main">
        <account-statement />
      </div>
  </layout-with-menu>
</template>

<script>
import AccountStatement from './AccountStatement'
import HeroHeader from '@/components/hero-header/HeroHeader'
import { mapActions } from 'pinia'
import { useAccountStatementStore } from '@account/store/accountStatementStore'

export default {
  components: {
    AccountStatement,
    HeroHeader
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('EDB_transactions', 'Transactions')
      }
    }
  },
  methods: {
    ...mapActions(useAccountStatementStore, ['fetchAccountsForStatement', 'fetchAccountStatementSettings'])
  },
  async mounted () {
    try {
      await this.fetchAccountStatementSettings()
      await this.fetchAccountsForStatement()
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
$account-statement-max-width: 1140px;

.my-transactions {
  &__main {
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: $account-statement-max-width;
    width: 100%;
  }
}
</style>
