<template>
  <bb-navbar
    id="menu"
    ref="navbar"
    flavor="public"
    sidebar
    logoTo="/"
    :links="links"
    :user="user"
    :theme="theme"
    :addButton="!!addClick"
    :languages="languages"
    @addClick="addClickHandler"
    @languageClick="switchLanguage"
  >
    <template v-if="isAccountSwitcherShown" #accountSwitcher>
      <account-switcher />
    </template>
    <template v-if="isAccountLimitNotificationVisible" #notification>
      <bb-box
        thin
        class="d-flex flex-column align-items-start f-smallest m-x-30 m-b-30 limit-box"
      >
        <span
          class="f-gotham-bold uppercase f-color-gray p-b-5"
          v-sanitize.basic="translations.freeLimitAmount"
        />
        <span class="f-color-gray">
          {{ translations.toIncrease }}
        </span>
        <bb-link :to="{ name: AccountRouteName.AccountVerification }">
          {{ translations.learnMore }}
        </bb-link>
      </bb-box>
    </template>
  </bb-navbar>
</template>

<script>
import { mapStores, mapState, mapActions } from 'pinia'
import { useAccountStore } from '@account/store/accountStore'
import logoDarkImg from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png'
import logoLightImg from '@bigbank/interface-components/dist/img/bigbank@2x.png'
import { translationPicker } from '@/plugins/translations'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import LogoutReason from '@/types/logout-reason'
import { AccountRouteName } from '@account/const'
import AccountSwitcher from './AccountSwitcher'
import { DepositType } from '@deposits/const'
import { isNil } from 'lodash'
import { useRootStore } from '../store/root'
import { useLoanStore } from '@loan/store/loanStore'

export default {
  name: 'self-menu',
  data () {
    return {
      logoLightImg,
      logoDarkImg,
      AccountRouteName
    }
  },
  components: {
    AccountSwitcher
  },
  props: {
    addClick: {
      required: false,
      default: null
    }
  },
  methods: {
    ...mapActions(useRootStore, ['changeLanguage', 'getContractsCount', 'getDepositsCount']),
    isActive (path) {
      if (!Array.isArray(path)) {
        path = [path]
      }

      const currentPath = this.$route.path || '/'
      return path.includes(currentPath) || path.find(p => currentPath.startsWith(p))
    },
    switchLanguage (lang) {
      this.changeLanguage(lang.key)
    },
    toggleSideMenu () {
      this.$refs.navbar.onDrawerToggle()
    },
    addClickHandler ($event) {
      if (!this.addClick) {
        return
      }
      this.addClick($event)
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    ...mapState(useRootStore, [
      'isLoggedIn',
      'company',
      'isCustomer',
      'isCompany',
      'featureFlags',
      'username',
      'language',
      'contractsCount',
      'depositsCount',
      'relatedEntities',
      'relatedEntities',
      'hasCurrentAccount',
      'isChannelEE',
      'channelConfig',
      'supportedLanguages',
      'hasCountryStoppedIssuingLoans'
    ]),
    ...mapState(useLoanStore, ['hasCreditCardContract']),
    translations () {
      const translations = {
        freeLimitAmount: this.$pgettext('account_verification', 'Account is limited'),
        toIncrease: this.$pgettext('account_verification', 'To increase your account limit we need to verify you account.'),
        learnMore: this.$pgettext('account_verification', 'Learn more'),
        accountStatement: this.$pgettext('EDB', 'Account statement'),
        myTransactions: this.$pgettext('EDB', 'My transactions'),
        myCreditCard: this.$pgettext('menu_sidebar', 'My credit card'),
        creditCard: this.$pgettext('menu_sidebar', 'Credit card'),
        settings: this.$pgettext('menu_sidebar', 'Settings'),
        service: this.$pgettext('menu_sidebar', 'Service'),
        support: this.$pgettext('menu_sidebar', 'Support'),
        logout: this.$pgettext('menu_sidebar', 'Log out'),
        help: this.$pgettext('menu_sidebar', 'Help'),
        interestRates: this.$pgettext('menu', 'Interest rates'),
        privateCustomer: this.$gettext('Private customer'),
        payments: this.$gettext('Payments'),
        dashboard: this.$gettext('Dashboard'),
        apply: {
          apply: this.$pgettext('menu_sidebar', 'Apply'),
          applyThroughPartner: this.$pgettext('sales_through_partner', 'Apply'),
          applyLeasing: this.$pgettext('menu_sidebar', 'Apply for leasing'),
          applyHousingLoan: this.$pgettext('menu_sidebar', 'Apply for housing loan'),
          applyConsumerLoan: this.$pgettext('menu_sidebar', 'Apply for consumer loan'),
          applyRevolvingLoan: this.$pgettext('menu_sidebar', 'Apply for revolving loan'),
          applyCreditCard: this.$pgettext('credit_card_onboarding', 'Apply for credit card')
        },
        deposits: {
          deposits: this.$pgettext('menu_sidebar', 'Deposits'),
          myDeposits: this.$pgettext('menu_sidebar', 'My deposits'),
          termDeposit: this.$pgettext('menu_sidebar', 'Term Deposit'),
          demandDeposit: this.$pgettext('menu_sidebar', 'Demand Deposit'),
          newDeposit: this.$pgettext('menu_sidebar', 'Apply for deposits')
        },
        loans: {
          loans: this.$pgettext('menu_sidebar', 'Loans'),
          myLoans: this.$pgettext('menu_sidebar', 'My loans'),
          revolvingLoan: this.$pgettext('menu_sidebar', 'Revolving Loan'),
          loanContracts: this.$pgettext('menu_sidebar', 'Loan Contracts')
        }
      }

      if (this.isCompany) {
        Object.assign(translations.deposits, {
          demandDeposit: this.$pgettext('corporate_menu_sidebar', 'Demand Deposit')
        })
      }

      return translations
    },
    isAccountSwitcherShown () {
      return this.relatedEntities.length > 1
    },
    theme () {
      return this.isCompany ? 'corporate' : 'private'
    },
    languages () {
      return this.supportedLanguages.length > 1 ? this.supportedLanguages : null
    },
    user () {
      if (!this.isLoggedIn) {
        return null
      }
      if (!this.isCustomer) {
        return {
          link: {
            label: this.translations.logout,
            to: `/logout?reason=${LogoutReason.MENU_BUTTON_CLICK_NOT_CUSTOMER}`
          }
        }
      }

      return {
        name: this.isCompany
          ? this.company.representativeName
          : this.username,
        role: this.isCompany
          ? this.company.name
          : this.translations.privateCustomer,
        to: ((this.featureFlags.myAccount && '/account') || undefined),
        link: {
          label: this.translations.logout,
          to: `/logout?reason=${LogoutReason.MENU_BUTTON_CLICK}`
        }
      }
    },
    hasApplySections () {
      return this.applyLoanSections.length > 0
    },
    myLoansSections () {
      const section =
      {
        to: '/my-active-loans',
        id: 'menu-link-my-active-loans',
        label: this.translations.loans.myLoans,
        iconAfter: this.contractsCount?.debt?.showLoanInDebtWarning
          ? { name: 'ui-alert', fill: 'yellow' }
          : null,
        active: this.isActive(['/my-active-loans', '/my-revolving-loan']),
        sections: [
          {
            to: '/my-active-loans',
            id: 'menu-section-link-my-active-loans',
            label: this.translations.loans.loanContracts,
            active: this.isActive('/my-active-loans'),
            disabled: !this.featureFlags.enableRevolvingLoan
          },
          {
            to: '/my-revolving-loan',
            id: 'menu-section-link-my-revolving-loans',
            label: this.translations.loans.revolvingLoan,
            active: this.isActive('/my-revolving-loan'),
            disabled: !this.featureFlags.enableRevolvingLoan
          }
        ]
      }

      if (!this.featureFlags.disableApplyForALoan) {
        return [
          section,
          ...this.applyLoanSections
        ]
      }

      return section
    },
    applyLoanSections () {
      const sections = []

      if (this.featureFlags.disableApplyForALoan) {
        return sections
      }

      for (const property in this.channelConfig.applyLoanUrl) {
        let label = ''
        let id = ''
        let disabled = false

        switch (property) {
          case InternalLoanType.LOAN:
            id = 'menu-section-link-apply-for-consumer-loan'
            label = this.translations.apply.applyConsumerLoan
            break
          case InternalLoanType.HOUSING:
            id = 'menu-section-link-apply-for-housing-loan'
            label = this.translations.apply.applyHousingLoan
            break
          case InternalLoanType.REVOLVING:
            id = 'menu-section-link-apply-for-revolving-loan'
            label = this.translations.apply.applyRevolvingLoan
            break
          case InternalLoanType.LEASING:
            id = 'menu-section-link-apply-for-leasing'
            label = this.translations.apply.applyLeasing
            break
          case InternalLoanType.CREDIT_CARD:
            id = 'menu-section-link-apply-for-credit-card'
            label = this.translations.apply.applyCreditCard
            disabled = !this.isApplyForCreditCardVisible
            break
        }

        if (label) {
          sections.push({
            id,
            label,
            disabled,
            target: '_blank',
            to: translationPicker(this.channelConfig.applyLoanUrl[property], this.lang)
          })
        }
      }

      return sections
    },
    links () {
      const toLink = ({ ...link }) => {
        if (Array.isArray(link.sections) && !link.sections.length) {
          delete link.sections
        }
        return link
      }
      const filterDisabled = ({ disabled }) => !disabled

      return this.computedRoutes
        .filter(filterDisabled)
        .map(item => {
          item.sections = (item.sections || []).filter(filterDisabled)
          return item
        })
        .map(toLink)
    },
    computedRoutes () {
      return [
        {
          to: '/dashboard',
          id: 'menu-link-dashboard',
          label: this.translations.dashboard,
          active: this.isActive(['/dashboard']),
          disabled: !this.featureFlags.enableDashboard
        },
        {
          to: '/payments',
          id: 'menu-link-payments',
          label: this.translations.payments,
          active: this.isActive(['/payments']),
          disabled: !this.featureFlags.enablePayments || !this.hasCurrentAccount
        },
        {
          to: '/my-transactions',
          id: 'menu-link-my-transactions',
          label: this.translations.accountStatement,
          active: this.isActive(['/my-transactions']),
          disabled: this.isMyTransactionsDisabled
        },
        {
          to: '/my-deposits',
          id: 'menu-link-deposits',
          label: this.translations.deposits.deposits,
          active: this.isActive('/deposits'),
          disabled: !this.isTermDepositEnabled && !this.isDemandDepositEnabled && this.isApplyForNewDepositDisabled,
          sections: [
            {
              to: '/my-deposits/new-deposit',
              id: 'menu-section-link-new-deposit',
              label: this.translations.deposits.newDeposit,
              active: this.isActive('/my-deposits/new-deposit'),
              disabled: this.isApplyForNewDepositDisabled
            },
            {
              to: '/my-deposits/demand',
              id: 'menu-section-link-demand-deposit',
              label: this.translations.deposits.demandDeposit,
              active: this.isActive('/my-deposits/demand'),
              disabled: !this.isDemandDepositEnabled
            },
            {
              to: '/my-deposits/term',
              id: 'menu-section-link-term-deposit',
              label: this.translations.deposits.termDeposit,
              active: this.isActive('/my-deposits/term'),
              disabled: !this.isTermDepositEnabled
            }
          ]
        },
        {
          to: '/my-credit-card',
          id: 'menu-link-my-credit-card',
          label: this.translations.creditCard,
          iconAfter: this.contractsCount?.debt.showCreditCardInDebtWarning
            ? { name: 'ui-alert', fill: 'yellow' }
            : null,
          active: this.isActive(['/my-credit-card']),
          disabled: !this.isCreditCardVisibleInMenu
        },
        {
          to: '/my-active-loans',
          id: 'menu-link-my-active-loans',
          label: this.translations.loans.loans,
          iconAfter: this.contractsCount?.debt?.showLoanInDebtWarning
            ? { name: 'ui-alert', fill: 'yellow' }
            : null,
          active: this.isActive(['/my-active-loans']),
          disabled: this.isMyActiveLoansDisabled,
          sections: this.myLoansSections.length > 1
            ? this.myLoansSections
            : undefined
        },
        {
          to: '/apply-through-partner',
          label: this.translations.apply.apply,
          active: this.isActive(['/apply-through-partner']),
          disabled: this.isApplyThroughPartnerDisabled
        },
        {
          to: this.channelConfig.interestRatesUrl,
          id: 'menu-link-interest-rates',
          label: this.translations.interestRates,
          target: '_blank',
          iconAfter: {
            name: 'ui-open-in-new'
          },
          disabled: this.isInterestRatesUrlDisabled
        },
        {
          to: '/account',
          id: 'menu-link-settings',
          label: this.translations.settings,
          active: window.location.pathname.startsWith('/account'),
          disabled: !this.featureFlags.myAccount || !this.isCustomer
        },
        {
          to: translationPicker(this.channelConfig.helpUrl, this.lang),
          id: 'menu-link-help',
          label: this.isCompany
            ? this.translations.support
            : this.translations.help,
          disabled: !this.channelConfig.helpUrl,
          target: '_blank'
        },
        {
          to: translationPicker(this.channelConfig.serviceUrl, this.lang),
          id: 'menu-link-service',
          label: this.translations.service,
          disabled: !this.channelConfig.serviceUrl,
          target: '_blank'
        }
      ]
    },
    isApplyForCreditCardVisible () {
      if (this.isChannelEE) {
        return false
      }
      if (!this.featureFlags.enableCreditCardProduct) {
        return false
      }
      if (this.hasCreditCardContract) {
        return false
      }
      return this.featureFlags.enableCreditCardSilentLaunchCrossSell === false
    },
    isCreditCardVisibleInMenu () {
      if (!this.featureFlags.enableCreditCardProduct) {
        return false
      }
      if (this.hasCreditCardContract) {
        return true
      }
      return this.featureFlags.enableCreditCardSilentLaunchCrossSell === false
    },
    isAccountLimitNotificationVisible () {
      return this.featureFlags.enableUnverifiedCustomerAdditionalLimits &&
        !(this.accountStore.verificationData?.isF2fIdentified ?? true)
    },
    isApplyForNewDepositDisabled () {
      const hasDemandDeposit = this.depositsCount?.totalByType[DepositType.Demand] > 0
      const hasDemandDepositInfo = !isNil(this.depositsCount?.totalByType[DepositType.Demand])
      return !this.featureFlags.enableApplyTermDeposits &&
        (!this.featureFlags.enableApplyDemandDeposits || !hasDemandDepositInfo || hasDemandDeposit)
    },
    isTermDepositDisabled () {
      return !this.depositsCount?.historicalTotalByType?.[DepositType.Term] && !this.featureFlags.enableTermDeposits
    },
    isDemandDepositDisabled () {
      return !this.depositsCount?.historicalTotalByType?.[DepositType.Demand] && !this.featureFlags.enableDemandDeposits
    },
    isTermDepositEnabled () {
      return !!this.depositsCount?.historicalTotalByType?.[DepositType.Term] || (this.featureFlags.enableTermDeposits && !this.isCompany)
    },
    isDemandDepositEnabled () {
      return !!this.depositsCount?.historicalTotalByType?.[DepositType.Demand] || (this.featureFlags.enableDemandDeposits && !this.isCompany)
    },
    isInterestRatesUrlDisabled () {
      return !(this.featureFlags.enableInterestRatesLink && this.channelConfig.interestRatesUrl)
    },
    isMyActiveLoansDisabled () {
      return (
        !this.featureFlags.myActiveLoans ||
        (this.hasCountryStoppedIssuingLoans && this.contractsCount?.total === 0)
      )
    },
    isMyTransactionsDisabled () {
      return !this.featureFlags.enableMyTransactions
    },
    isApplyThroughPartnerDisabled () {
      return !this.featureFlags.enableApplyThroughPartner || this.contractsCount?.activeContracts === 0
    }
  },
  async mounted () {
    await this.getContractsCount()
    await this.getDepositsCount()
  },
  created () {
    window.addEventListener('toggleSideMenu', this.toggleSideMenu)
  },
  destroyed () {
    window.removeEventListener('toggleSideMenu', this.toggleSideMenu)
  }
}
</script>

<style scoped lang="scss">
.limit-box {
  line-height: 1.3
}
</style>
