import Errors from '../views/ErrorsView'
import ErrorViewName from '@/types/error-view-name'
import { ROUTER_PATHS } from '../const'
import { apm } from '@elastic/apm-rum'
import { useRootStore } from '../store/root'
import { translationPicker } from '@/plugins/translations'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { ErrorTextCode: BackendErrorTextCode } = require('../../server/error-text-code.enum')

const ErrorHandler = {}

function mapErrorToApmError (error) {
  const message = [
    'Request failed',
    `${error.method} ${error.requestUrl}`,
    `Message: ${error.message}`,
    `StatusCode: ${error.statusCode}`,
    `ErrorCode: ${error.errorCode}`,
    `CorrelationId: ${error.correlationId}`
  ].join(' ')
  const apmError = new Error(message)
  apmError.correlationId = error.correlationId
  apmError.statusCode = error.statusCode
  apmError.requestUrl = error.requestUrl
  apmError.errorCode = error.errorCode

  return apmError
}

ErrorHandler.install = function (Vue, router) {
  /**
   * @function sendErrorEvent emits error event to EventBus
   * @param {string} errorCode error code identifying specific error page to be loaded
   * */
  function sendErrorEvent (errorCode, params) {
    window.eventBus.$emit('error-page', Errors, { errorCode, params })
  }

  function getErrorViewNameByBackendErrorTextCode (error) {
    return {
      [BackendErrorTextCode.REQUEST_FAILED]: (() => {
        if (error.statusCode === 504) return ErrorViewName.NO_CONNECTION
        if (error.statusCode === 503) return ErrorViewName.MAINTENANCE
        return null
      })(),
      [BackendErrorTextCode.NO_APPLICATIONS]: ErrorViewName.NO_APPLICATIONS,
      [BackendErrorTextCode.MAINTENANCE_ENABLED]: ErrorViewName.MAINTENANCE,
      [BackendErrorTextCode.TERMS_NOT_ACCEPTED]: ErrorViewName.NO_ACCEPT,
      [BackendErrorTextCode.SESSION_EXPIRED]: ErrorViewName.SESSION_EXPIRED,
      [BackendErrorTextCode.INVALID_CUSTOMER]: ErrorViewName.NO_CUSTOMER,
      [BackendErrorTextCode.INVALID_CONTRACT]: ErrorViewName.NO_CONTRACT,
      [BackendErrorTextCode.NO_ACCESS]: ErrorViewName.NO_ACCESS
    }[error.type] ?? null
  }

  /***
   * @param {Object} errorObj - Custom object containing error type and error
   * @param {string} errorObj.type - One of the following: ['REQUEST_FAILED', 'NO_APPLICATIONS', 'TERMS_NOT_ACCEPTED', 'SESSION_EXPIRED']
   * @param {Object} errorObj.error - Created error object
   */
  function handler (errorObj) {
    const rootStore = useRootStore()

    switch (errorObj.type) {
      case BackendErrorTextCode.CONFIRM_DATA:
        if (!rootStore.isDocumentUploadSkippedInConfirmData) {
          router.push(ROUTER_PATHS.CONFIRM_DATA)
        }
        break
      case BackendErrorTextCode.CONTACT_CUSTOMER_SUPPORT:
      case BackendErrorTextCode.NOT_CLIENT:
        rootStore.showContactCustomerSupportErrorModal()
        break
      case BackendErrorTextCode.SIGNING_NOT_SUPPORTED:
        rootStore.showSigningNotSupportedErrorModal(errorObj)
        break
      case BackendErrorTextCode.SIGNING_REQUEST_EXPIRED:
        rootStore.showSigningRequestExpiredErrorModal()
        break
      case BackendErrorTextCode.OPEN_SAVINGS_DEPOSIT:
        rootStore.showOpenSavingsDepositErrorModal()
        break
      case BackendErrorTextCode.OPEN_CURRENT_ACCOUNT:
        rootStore.showOpenCurrentAccountErrorModal()
        break
      case BackendErrorTextCode.COMPLETE_CURRENT_ACCOUNT_APPLICATION:
        document.location = translationPicker(rootStore.channelConfig.currentAccountApplicationUrl, rootStore.language)
        break
      default: {
        const errorViewName = getErrorViewNameByBackendErrorTextCode(errorObj)
        errorViewName ? sendErrorEvent(errorViewName) : sendErrorEvent(ErrorViewName.HTTP_STATUS_500, errorObj)
        if (!errorViewName || errorObj.statusCode >= 500) {
          apm.captureError(mapErrorToApmError(errorObj?.error ?? errorObj))
        }
      }
    }
  }

  Vue.handleError = handler
  Vue.prototype.$handleError = handler
}

export default ErrorHandler
