<template>
  <bb-modal v-bind="modalProps" data-testid="current-account-modal">
    <div class="header">{{ modalTitle }}</div>
    <div class="body">
      <bb-icon class="icon" name="illustration-money" size="fluid" fill="mint-70" />
      <div class="m-t-35 text f-small f-medium">{{ modalText }}</div>
      <div class="m-t-20 text f-small" v-sanitize.basic="translations.continueText" />
    </div>
    <div slot="footer" class="modal-footer">
      <div class="logout-wrapper">
        <bb-button v-bind="cancelButtonProps" @click="onCancel" data-testid="skip-current-account-button"/>
      </div>
      <div class="continue-wrapper">
        <bb-button v-bind="continueButtonProps" @click="onContinue" data-testid="create-current-account-button"/>
      </div>
    </div>
  </bb-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import LogoutReason from '@/types/logout-reason'
import '@bigbank/interface-components/dist/svg/illustration/money'
import { translationPicker } from '@/plugins/translations'
import { useRootStore } from '../store/root'
import api from '@/api'

export default {
  data () {
    return {
      areActionButtonsDisabled: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile', 'language', 'channelConfig', 'canSkipCurrentAccountFlow']),
    translations () {
      const translations = {
        modalTitle: this.$pgettext('open_current_account_modal', 'Create your current account already today!'),
        modalText: this.$pgettext('open_current_account_modal', 'Now you can make payments via Bigbank by opening a current account.'),
        continue: this.$pgettext('open_current_account_modal', 'Continue'),
        logout: this.$pgettext('open_current_account_modal', 'Log out'),
        askLater: this.$pgettext('open_current_account_modal', 'Ask later'),
        continueText: this.$pgettext('open_current_account_modal',
          'Click continue to start! For more info ' +
          'read the <a target=\'_blank\' href=\'#\'>Terms and Conditions</a> and ' +
          '<a target=\'_blank\' href=\'#\'>Product information</a>.')
      }

      return translations
    },
    modalTitle () {
      return this.translations.modalTitle
    },
    modalText () {
      return this.translations.modalText
    },
    modalProps () {
      return {
        flavor: this.isMobile ? 'card' : 'user',
        fullMobile: true,
        centerVertically: true,
        hideClose: true,
        staticOverlay: true,
        visible: true,
        publicSize: 'm'
      }
    },
    cancelButtonProps () {
      const defaults = {
        color: 'gray',
        inverted: false,
        label: this.translations[!this.canSkipCurrentAccountFlow ? 'logout' : 'askLater'],
        disabled: this.areActionButtonsDisabled,
        noBackground: true,
        isLoading: this.isLoading
      }

      if (this.isMobile) {
        return {
          ...defaults,
          noBackground: false,
          inverted: true,
          display: 'block'
        }
      }

      return {
        ...defaults,
        class: 'w-100'
      }
    },
    continueButtonProps () {
      const defaults = {
        class: 'w-100',
        label: this.translations.continue,
        disabled: this.areActionButtonsDisabled
      }

      return {
        ...defaults
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['checkAuthentication']),
    initiateLogout () {
      this.areActionButtonsDisabled = true
      this.$router.push({ path: '/logout', query: { reason: LogoutReason.OPEN_CURRENT_ACCOUNT_MODAL } })
    },
    onContinue () {
      document.location = translationPicker(this.channelConfig.currentAccountOnboardingUrl, this.lang)
    },
    async onCancel () {
      if (!this.canSkipCurrentAccountFlow) {
        this.initiateLogout()
      } else {
        this.isLoading = true
        this.areActionButtonsDisabled = true
        try {
          await api.skipCurrentAccountConversion()
          this.$router.push({ path: '/' })
        } finally {
          this.isLoading = false
          this.areActionButtonsDisabled = false
        }
      }
    }
  },
  async mounted () {
    await this.checkAuthentication()
  }
}
</script>

<style lang="scss" scoped>
.header {
  color: $blue;
  font-weight: 500;
  font-size: $font-size-small;
  font-family: $gotham-medium;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    max-width: 250px;
  }

  .text {
    color: $gray;
    text-align: center;
    font-family: $gotham-book;
    line-height: 18px;
    width: 333px;

    @media (max-width: $mobile-view-breaking-point) {
      width: auto;
      max-width: 100%;
    }
  }

  ::v-deep a {
    color: $blue;
    text-decoration: none;
  }
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  padding: 0 10px 10px;

  @media (max-width: $mobile-view-breaking-point) {
    padding: 20px;
  }

  div {
    flex: 1 0 0;
  }
}
</style>
