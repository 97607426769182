import Vue from 'vue'
import router from '@/views'
import saveAs from 'file-saver'
import nc from '@bigbank/dc-common/util/namecase'
import axios, { HttpStatusCode } from 'axios'
import { omitBy, isUndefined } from 'lodash'
import { retryStrategy } from './retry-strategy'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { ErrorTextCode: BackendErrorTextCode } = require('../../server/error-text-code.enum')

retryStrategy(axios, { maxRetryAttempts: 3, baseRetryDelayMs: 1000 })

const abortController = new AbortController()

// This method is used to cancel all requests
// It is called when user switches roles
// Can be used for other cases as well, when page needs to be reloaded
export function cancelAllRequests () {
  abortController.abort()
}

export function clearRequestConfigFromUndefinedValues (requestConfig) {
  return omitBy(requestConfig, isUndefined)
}

export function getErrorTextCodeFromResponse (data) {
  if (!isUndefined(data?.err_code)) return data.err_code
  if (!isUndefined(data?.errorCode)) return data.errorCode
  if (!isUndefined(data?.errCode)) return data.errCode

  return null
}

/**
 * @param {string} url - url string
 * @param {Object} options
 * @param {boolean} options.isBlob true or false
 * @param {Object} options.body Request body
 * @param {string} options.method 'GET' | 'POST' | 'PUT' 'DELETE'
 * @param {boolean} options.errHandlerOpts.handleOnlyKnownErrors true or false
 * @param {function} errorHandler
 */

export const request = (
  url,
  options = {}
) => {
  const requestConfig = Object.assign({
    url,
    method: options.method?.toLowerCase() ?? 'get',
    data: options.body ?? {},
    params: options.params ?? {},
    headers: { 'Content-Type': options.contentType ?? 'application/json' },
    credentials: 'include',
    responseType: options.isBlob ? 'blob' : 'json',
    withCredentials: true,
    signal: abortController.signal,
    validateStatus: () => true // Disable error throw on every request, handle manually
  })

  if (isObject(options.headers)) {
    Object.assign(requestConfig.headers, options.headers)
  }

  if (abortController.signal.aborted) {
    return
  }

  return axios.request(clearRequestConfigFromUndefinedValues(requestConfig))
    .then((response) => {
      const isStatusCodeSuccesful = (response.status >= 200 && response.status < 300) || [302, 304].includes(response.status)
      const hasUnsupportedMediaTypeStatusCode = response.status === HttpStatusCode.UnsupportedMediaType
      const errorTextCode = getErrorTextCodeFromResponse(response.data)

      // Prepare and throw error
      if (!isStatusCodeSuccesful) {
        const error = new Error(response?.data?.message ?? '')

        if (errorTextCode) { // Note: just in case defined all variants
          error.err_code = errorTextCode
          error.errCode = errorTextCode
          error.errorCode = errorTextCode
        }

        error.data = response.data
        error.correlationId = response.headers['x-correlation-id'] ?? undefined
        error.statusCode = response.status
        error.requestUrl = requestConfig.url
        error.method = requestConfig.method

        !hasUnsupportedMediaTypeStatusCode && normalErrorHandler(error, options.errHandlerOpts)
      }

      return response.data
    }).catch((error) => {
      if (axios.isCancel(error)) {
        // This is a canceled request
        // Do nothing to prevent errors
        return new Promise(() => {})
      } else {
        throw error
      }
    })
}

export const downloadFile = async function (url, fileName, options = {}) {
  const data = await request(url, {
    method: options.method?.toLowerCase() ?? 'get',
    body: options.body ?? {},
    params: options.params ?? {},
    contentType: options.contentType ?? null,
    errHandlerOpts: {
      handleOnlyKnownErrors: options.errHandlerOpts?.handleOnlyKnownErrors ?? true
    },
    isBlob: true
  })
  const blob = new Blob([data], { type: options.contentType ?? 'application/octet-stream' })

  return saveAs(blob, fileName)
}

export const getCountryInfo = function (countryCode, countryList) {
  return countryList.find(country => country.countryCode === countryCode) || {}
}

export const titleCase = function (str) {
  if (!str) {
    return str
  }
  return nc(str)
}

export const sleep = function (ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export function normalErrorHandler (err, errHandlerOpts = {}) {
  const errParams = {
    correlationId: err.correlationId,
    error: err
  }

  switch (err.err_code) {
    case BackendErrorTextCode.ACCEPT_TERMS:
      if (router.currentRoute.name !== 'terms') {
        router.push('/terms?redirect=' + router.currentRoute.fullPath)
      }
      return true
    case BackendErrorTextCode.INVALID_CUSTOMER:
    case BackendErrorTextCode.NO_ACCESS:
    case BackendErrorTextCode.CONFIRM_DATA:
    case BackendErrorTextCode.INVALID_CONTRACT:
    case BackendErrorTextCode.SESSION_EXPIRED:
    case BackendErrorTextCode.CONTACT_CUSTOMER_SUPPORT:
    case BackendErrorTextCode.MAINTENANCE_ENABLED:
    case BackendErrorTextCode.SIGNING_NOT_SUPPORTED:
    case BackendErrorTextCode.SIGNING_REQUEST_EXPIRED:
    case BackendErrorTextCode.OPEN_SAVINGS_DEPOSIT:
    case BackendErrorTextCode.OPEN_CURRENT_ACCOUNT:
    case BackendErrorTextCode.COMPLETE_CURRENT_ACCOUNT_APPLICATION:
    case BackendErrorTextCode.NOT_CLIENT:
      errParams.type = err.err_code
      break
    default:
      if (errHandlerOpts.handleOnlyKnownErrors) {
        throw err
      }
      errParams.type = BackendErrorTextCode.REQUEST_FAILED
      break
  }

  Vue.handleError(errParams)
  if (errHandlerOpts.throwOnAllErrors) {
    throw err
  }
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export const isObject = function (item) {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export const mergeDeep = function (target, ...sources) {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const loadScript = async (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = resolve(true)
    script.onError = reject(new Error('Script failed to load'))
    script.addEventListener('error', resolve(true))
    script.addEventListener('load', reject(new Error('Script failed to load')))
    script.src = src
    document.head.appendChild(script)
  })
}

export function splitTextAndLinks (inputString) {
  if (!inputString) {
    return []
  }

  const result = []
  const pattern = /<a\s+([^>]*)>(.*?)<\/a>/g

  let lastIndex = 0
  let match

  while ((match = pattern.exec(inputString)) !== null) {
    const linkStartIndex = match.index
    const linkText = match[2]

    if (lastIndex !== linkStartIndex) {
      const precedingText = inputString.substring(lastIndex, linkStartIndex)
      result.push({ type: 'text', content: precedingText })
    }

    const attributesString = match[1]
    const attributes = {}
    const attributePattern = /(\w+)\s*=\s*['"]([^'"]*)['"]/g
    let attributeMatch

    while ((attributeMatch = attributePattern.exec(attributesString)) !== null) {
      const attributeName = attributeMatch[1]
      const attributeValue = attributeMatch[2]
      attributes[attributeName] = attributeValue
    }

    result.push({ type: 'link', attributes, content: linkText })

    lastIndex = pattern.lastIndex
  }

  if (lastIndex < inputString.length) {
    const remainingText = inputString.substring(lastIndex)
    result.push({ type: 'text', content: remainingText })
  }

  return result
}
