<template>
  <bb-overlay
    v-if="hasCampaigns"
    color="dark"
    :visible="visible"
    @close="onClose"
  >
    <bb-product-card
      closeButton
      v-bind="card"
      @close="onClose"
      @click="onClick"
    ></bb-product-card>
  </bb-overlay>
</template>

<script>
import { BbProductCard } from '@bigbank/interface-components'
import { CAMPAIGNS } from '@/TrackingActions'

export default {
  name: 'campaign-modal',
  components: { BbProductCard },
  data: () => {
    return {
      tracked: false
    }
  },
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    hasCampaigns () {
      return this.campaigns && this.campaigns.length > 0
    },
    card () {
      if (this.hasCampaigns) {
        return this.getCardProps(this.campaigns[0])
      }

      return {}
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    },
    getCardProps (campaign) {
      const card = {
        id: campaign.id,
        title: campaign.title,
        icon: campaign.icon,
        imagePath: campaign.imagePath,
        loading: false,
        description: campaign.description,
        button: campaign.button,
        columns: campaign.columns
      }

      if (campaign.loyaltyLabel && campaign.loyaltyLabel.length > 0) {
        card.loyaltyLabel = campaign.loyaltyLabel
      } else if (campaign.campaignLabel && campaign.campaignLabel.length > 0) {
        card.campaignLabel = campaign.campaignLabel
      }

      return card
    },
    onClick () {
      if (this.card && this.card.id) {
        this.$tracker.action(CAMPAIGNS.CLICK, {}, { campaignIds: [this.card.id] })
      }
      this.$emit('close')
    }
  },
  mounted () {
    if (this.card && this.card.id && this.hasCampaigns && !this.tracked) {
      this.$tracker.action(CAMPAIGNS.VIEW_MODAL, {}, { campaignIds: [this.card.id] })
      this.tracked = true
    }
  }
}
</script>

<style lang="scss">
  .bb-product-cards__card-big {
    padding: 20px !important;
  }

  .bb-product-card__button {
    width: 95%;
  }
</style>
