<template>
  <div class="invoice__content">
    <div class="invoice-title f-gotham-book f-small">{{ invoiceTitle }}</div>
    <div class="total-amount m-t-25" :class="totalAmountClass">{{ totalAmount }}</div>
    <div class="subtitle" v-if="!!subtitleText">{{ subtitleText }}</div>
    <bb-list-group class="invoice__details-list m-t-30" :class="{ disabled: isInvoiceDetailsDisabled }">
      <bb-list-item
        v-for="item in visibleInvoiceItems"
        :key="item.key"
        :title="item.title"
        extraCondensed
      >
        <span :name="item.key">{{ item.value }}</span>
        <bb-tooltip class="m-l-5" v-if="item.tooltip">
          {{item.tooltip}}
        </bb-tooltip>
      </bb-list-item>
    </bb-list-group>
  </div>
</template>

<script>
import { formatMoneyWithCurrency, isNotZero } from '@/plugins/numformat'
import { mapState } from 'pinia'
import { formatDate } from '@/plugins/dateFormatters'
import payBackModalMixin from '@/mixins/payBackModalMixin'
import { LoanTypeCode } from '../helpers/loan.enum'
import { useRootStore } from '../../../store/root'

const InvoiceItems = {
  PrincipalAmount: 'principalAmount',
  InterestAmount: 'interestAmount',
  MonthlyFee: 'monthlyFee',
  ConclusionFeeAmount: 'conclusionFeeAmount',
  AltumFeeAmount: 'altumFeeAmount',
  LetterFeeAmount: 'letterFeeAmount',
  AdministrationFeeAmount: 'administrationFeeAmount',
  PenaltyAmount: 'penaltyAmount',
  ScheduleDebtAmount: 'scheduleDebtAmount',
  OtherFees: 'otherFees',
  TotalAmount: 'totalAmount',
  ObligationInterestAmount: 'obligationInterestAmount',
  PrincipalVatAmount: 'principalVatAmount'
}

export default {
  name: 'invoice-details',
  mixins: [payBackModalMixin],
  props: {
    contract: {
      required: true
    },
    invoiceDetails: {
      required: true
    },
    repaymentDetails: {
      required: true
    },
    nextPaymentDetails: {
      required: true
    },
    overrideTranslations: {
      required: false,
      default: () => ({})
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale']),
    translations () {
      return {
        payBack: this.$pgettext('credit_card_hero_header', 'Pay back'),
        dueDate: this.$pgettext('credit_card_hero_header', 'Due date'),
        noInvoice: this.$pgettext('pay_back', 'No invoice yet'),
        invoiceDue: this.$pgettext('pay_back', 'Invoice due %{date}'),
        interestTooltip: this.$pgettext('credit_card_pay_back', 'INTEREST_TOOLTIP'),
        revolvingLoanDebtSubtitle: this.$gettext('You do not have active invoice or You have not used Your credit limit yet.'),
        principalAmount: this.$gettext('Principal Amount'),
        interestAmount: this.$gettext('Interest Amount'),
        monthlyFee: this.$pgettext('credit_card_pay_back', 'Monthly fee'),
        conclusionFeeAmount: this.$gettext('Conclusion Fee'),
        altumFeeAmount: this.$gettext('Altum Fee'),
        letterFeeAmount: this.$gettext('Letter Fee'),
        administrationFeeAmount: this.$gettext('Account Administration'),
        penaltyAmount: this.$gettext('Penalty Amount'),
        scheduleDebtAmount: this.$gettext('Schedule Debt Amount'),
        otherFees: this.$gettext('Other Fees'),
        obligationInterestAmount: this.$gettext('Obligation interest'),
        principalVatAmount: this.$gettext('VAT'),
        ...this.overrideTranslations ?? {}
      }
    },
    isInvoiceDetailsDisabled () {
      return !this.isCreditCardLoan && !this.hasOutstandingFunds
    },
    invoiceTitle () {
      if (this.isCreditCardLoan) {
        return this.translations.payBack
      }
      if (this.isContractInDebt) {
        return this.$gettext('Debt Payment Details:')
      } else if (this.invoiceDetails) {
        return this.$gettext('Invoice Details') + ':'
      } else {
        return this.$gettext('Next Payment Details') + ':'
      }
    },
    totalAmount () {
      return this.invoiceItems.find(({ key }) => key === InvoiceItems.TotalAmount)?.value
    },
    totalAmountClass () {
      const showRed = this.isContractInDebt && (this.hasNextInvoice || !this.isCreditCardLoan)

      return {
        'color-red': showRed,
        'color-navy': !showRed
      }
    },
    interestAmount () {
      return this.invoiceItems.find(({ key }) => key === InvoiceItems.InterestAmount)?.value
    },
    fullRepaymentAmount () {
      const fullRepaymentAmount = this.repaymentDetails?.totalRepaymentAmount ?? null
      return formatMoneyWithCurrency(fullRepaymentAmount ?? 0, this.contract.currency, this.locale)
    },
    hasNextInvoice () {
      return !!this.invoiceDetails
    },
    formattedDate () {
      if (this.isContractInDebt) {
        return formatDate(this.contract.debtDueDate)
      }
      return formatDate((!this.invoiceDetails ? this.nextPaymentDetails : this.invoiceDetails)?.paymentDate)
    },
    invoiceItems () {
      const currency = this.contract.currency
      const locale = this.locale
      const zeroAs = '- ' + currency
      const takeDebtFromContract = this.isContractInDebt && (!this.isCreditCardLoan || !this.invoiceDetails)

      return [{
        key: InvoiceItems.PrincipalAmount,
        debtProperty: 'principalDebtAmount',
        show: !this.isCreditCardLoan
      }, {
        key: InvoiceItems.InterestAmount,
        debtProperty: 'interestDebtAmount',
        show: true,
        zeroAs,
        tooltip: this.interestTooltip
      }, {
        key: InvoiceItems.MonthlyFee,
        show: this.isCreditCardLoan,
        value: formatMoneyWithCurrency(0, currency, locale) // Yes, this is static, 0, from AC
      }, {
        key: InvoiceItems.ConclusionFeeAmount,
        debtProperty: 'conclusionFeeDebtAmount',
        show: isNotZero
      }, {
        key: InvoiceItems.AltumFeeAmount,
        debtProperty: 'altumFeeDebtAmount',
        show: isNotZero
      }, {
        key: InvoiceItems.LetterFeeAmount,
        show: isNotZero,
        amount: this.invoiceDetails?.letterFeeAmount ?? 0,
        value: formatMoneyWithCurrency(this.invoiceDetails?.letterFeeAmount ?? 0, currency, locale)
      }, {
        key: InvoiceItems.AdministrationFeeAmount,
        debtProperty: 'admFeeDebtAmount',
        show: isNotZero
      }, {
        key: InvoiceItems.PenaltyAmount,
        debtProperty: 'penaltyDebtAmount',
        show: isNotZero
      }, {
        key: InvoiceItems.ScheduleDebtAmount,
        show: isNotZero,
        amount: this.invoiceDetails?.scheduleDebtAmount ?? 0,
        value: formatMoneyWithCurrency(this.invoiceDetails?.scheduleDebtAmount ?? 0, currency, locale)
      }, {
        key: InvoiceItems.ObligationInterestAmount,
        debtProperty: 'obligationInterestDebtAmount',
        show: [
          LoanTypeCode.DEVELOPMENT_LOAN_EE01,
          LoanTypeCode.DEVELOPMENT_LOAN_LT01,
          LoanTypeCode.CREDIT_LINE_EE,
          LoanTypeCode.CREDIT_LINE_LT

        ].includes(this.contract?.loanTypeCode)
      }, {
        key: InvoiceItems.PrincipalVatAmount,
        debtProperty: 'principalVatDebtAmount',
        show: [LoanTypeCode.CAR_LEASE_EE03].includes(this.contract?.loanTypeCode)
      }, {
        key: InvoiceItems.OtherFees,
        debtProperty: 'otherDebtAmount',
        show: isNotZero
      }, {
        key: InvoiceItems.TotalAmount,
        debtProperty: 'totalDebtAmount',
        show: false,
        zeroAs
      }]
        .map(item => {
          item.title = this.translations[item.key]

          if (item.value === undefined && item.debtProperty) {
            const amount = takeDebtFromContract ? this.contract[item.debtProperty] : (this.invoiceDetails ?? this.nextPaymentDetails)?.[item.key]
            item.amount = amount
            item.value = formatMoneyWithCurrency(amount || 0, currency, locale, {
              zeroAs: item.zeroAs
            })
          }

          return item
        })
    },
    visibleInvoiceItems () {
      return this.invoiceItems
        .filter(({ show, amount }) => {
          if (typeof show === 'function') {
            return show(amount)
          }
          return show
        })
    },
    interestTooltip () {
      return this.isCreditCardLoan ? this.translations.interestTooltip : false
    },
    subtitleText () {
      if (this.isRevolvingLoan && !this.hasOutstandingFunds) {
        return this.translations.revolvingLoanDebtSubtitle
      } else if (this.isCreditCardLoan) {
        if (this.hasNextInvoice) {
          return this.translations.invoiceDue.replace('%{date}', this.invoiceDetails.paymentDate)
        } else if (!this.isContractInDebt) {
          return this.translations.noInvoice
        }
      }

      return `${this.translations.dueDate}: ${this.formattedDate}`
    }
  }
}
</script>

<style lang="scss">

.invoice {
  &__content {
    .invoice-title {
      color: $blue;
      font-size: $default-font-size;
      text-align: center;
      font-family: $gotham-medium;
      font-weight: bold;
    }

    .total-amount {
      text-align: center;
      font-family: $gotham-medium;
      font-size: $h3-size;
    }

    .subtitle {
      color: $gray-70;
      font-family: $gotham-medium;
      text-align: center;
      font-size: $font-size-tiny;
      font-weight: 400;
    }

    .notice {
      margin-top: 24px;
      padding-bottom: 16px;
      text-align: center;
      font-size: $font-size-small;
      color: $gray;
      font-family: $gotham-book;
      border-bottom: 1px dashed $gray-50;
    }

    .invoice__details-list.disabled {
      opacity: 0.3;
    }
  }
}
</style>
