module.exports.ErrorTextCode = Object.freeze({
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  REQUEST_FAILED: 'REQUEST_FAILED',
  NO_APPLICATIONS: 'NO_APPLICATIONS',
  MAINTENANCE_ENABLED: 'MAINTENANCE_ENABLED',
  TERMS_NOT_ACCEPTED: 'TERMS_NOT_ACCEPTED',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  INVALID_CUSTOMER: 'INVALID_CUSTOMER',
  INVALID_CONTRACT: 'INVALID_CONTRACT',
  NO_ACCESS: 'NO_ACCESS',
  CONFIRM_DATA: 'CONFIRM_DATA',
  CONTACT_CUSTOMER_SUPPORT: 'CONTACT_CUSTOMER_SUPPORT',
  OPEN_SAVINGS_DEPOSIT: 'OPEN_SAVINGS_DEPOSIT',
  OPEN_CURRENT_ACCOUNT: 'OPEN_CURRENT_ACCOUNT',
  COMPLETE_CURRENT_ACCOUNT_APPLICATION: 'COMPLETE_CURRENT_ACCOUNT_APPLICATION',
  SIGNING_NOT_SUPPORTED: 'SIGNING_NOT_SUPPORTED',
  SIGNING_REQUEST_EXPIRED: 'SIGNING_REQUEST_EXPIRED',
  NOT_CLIENT: 'NOT_CLIENT',
  UNAUTHORIZED: 'UNAUTHORIZED', // Note = Not handled at frontend
  ACCEPT_TERMS: 'ACCEPT_TERMS', // Note = Not handled at frontend
  NOT_FOUND: 'NOT_FOUND' // Note = Not handled at frontend
  // Note = Not handled at frontend
})
