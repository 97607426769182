<template>
  <layout-with-menu :loading="isLoading" class="payments">
    <hero-header :title="translations.title" />
    <signing-modal
      ref="signing"
      :initMethod="initPaymentSigning"
      @signingSuccessful="onSuccessfulPaymentSigning"
      @signingCancelled="onPaymentSigningCancelled"
    />
    <div class="payments__main">
      <template v-if="isPaymentResultShown">
        <payment-result/>
      </template>
      <template v-else >
        <bb-form @progress="onProgress">
          <div class="payments__heading">{{ translations.heading }}</div>
          <bb-select
            framed
            :label="translations.account"
            name="account"
            v-model="form.accountId"
            :options="accountOptions"
            v-validate="'required'"
            :data-vv-as="translations.account"
            :placeholder="translations.selectAccount"
            data-testid="payment-view-account-select"
          />
          <bb-input
            framed
            :label="translations.recipientName"
            name="recipientName"
            v-validate="'required|beneficiary-name'"
            data-vv-validate-on="blur"
            v-model="form.recipientName"
            :loading="beneficiaryValidation.isLoading"
            :data-vv-as="translations.recipientName"
            data-testid="payment-view-recipient-name-input"
          />
          <bb-input
            framed
            :label="translations.recipientIban"
            :formatter="formatIban"
            name="recipientIban"
            v-validate="'required|iban|beneficiary-iban'"
            data-vv-validate-on="blur"
            v-model.trim="form.recipientIban"
            :loading="beneficiaryValidation.isLoading"
            :data-vv-as="translations.recipientIban"
            data-testid="payment-view-recipient-iban-input"
          />
          <bb-input
            framed
            :label="translations.amount"
            v-validate="amountRules"
            :data-vv-as="translations.amount"
            v-model.number="form.amount"
            name="amount"
            type="number"
            :formatter="maxTwoDecimals"
            :add-on="currencySymbol"
            data-vv-validate-on="change|input"
            data-testid="payment-view-amount"
          />
          <div
            v-if="availableBalance !== null"
            class="payments__available-balance"
            :class="amountErrorCssClasses"
            data-testid="payment-view-available-balance-text"
          >
            {{ availableBalanceFormatted }}
          </div>
          <bb-banner
            v-if="hasFfLimitError"
            class="m-t-15 m-b-5"
            type="error"
            :title="translations.errors.ffLimit"
            permanent
            visible
          >
            <div class="f-color-gray">{{ translations.verifyAccount.description }}</div>
            <bb-button @click="onIdentify" class="m-t-5" color="gray" size="sm" inverted>{{ translations.verifyAccount.button }}</bb-button>
          </bb-banner>
          <bb-banner
            v-else-if="hasLimitsError"
            class="m-t-15 m-b-5"
            type="error"
            :title="translations.errors.limits[this.limitError.limitPeriodType]"
            permanent
            visible
          >
            <div class="f-color-gray">{{ translations.updateLimits[this.limitError.limitPeriodType] }}</div>
            <bb-button v-if="isUpdateLimitsButtonVisible" @click="onUpdateLimits" class="m-t-5" color="gray" size="sm" inverted>{{ translations.updateLimits.button }}</bb-button>
          </bb-banner>
          <bb-input
            framed
            :label="translations.description"
            :data-vv-as="translations.description"
            :maxlength="maxInputLengths.description"
            name="description"
            v-model="form.description"
            v-validate.continues="'atLeastOneFilled:referenceNumber|descriptionLength'"
            data-testid="payment-view-description"
          />
          <bb-input
            framed
            type="text"
            :label="translations.referenceNumber"
            :data-vv-as="translations.referenceNumber"
            :maxlength="maxInputLengths.reference"
            name="referenceNumber"
            v-model.trim="form.referenceNumber"
            v-validate.continues="'referenceCorrectness'"
            data-testid="payment-view-reference-number"
            @input="onReferenceNumberChanged"
          />
          <div slot="submit" slot-scope="{}" />
        </bb-form>
        <bb-button data-testid="payment-submit" display="block" v-bind="button" class="m-t-40" @click="submit">
          {{translations.submit}}
        </bb-button>
      </template>
    </div>
  </layout-with-menu>
</template>

<script>
import { isNil, isString } from 'lodash'
import HeroHeader from '@/components/hero-header/HeroHeader'
import SigningModal from '../../components/signing/SigningModal'
import PaymentResult from './PaymentResult'
import { PAYMENT_DESCRIPTION_MAX_LENGTH, PAYMENT_REFERENCE_MAX_LENGTH, PaymentStatus } from './const'
import { mapState, mapActions } from 'pinia'
import { currencyCodeToSymbol } from '@/plugins/currencyFormatters'
import { formatMoneyWithCurrency, maxTwoDecimals } from '@/plugins/numformat'
import iban from 'iban'
import api from '../../api'
import { useRootStore } from '../../../../store/root'
import { usePaymentStore } from '@account/store/paymentStore'
import { useAccountStore } from '@account/store/accountStore'
import { AccountRouteName } from '@account/const'
import { BeneficiaryValidationResultCode } from '@bigbank/dc-common/clients/http/account/account.enums'
import isValidReferenceNumber from '@bigbank/dc-common/validators/reference-number.validator'
import { CountryChannel } from '@bigbank/dc-common/config'

export default {
  components: {
    HeroHeader,
    SigningModal,
    PaymentResult
  },
  data () {
    return {
      isLoading: false,
      isValidationLoading: false,
      beneficiaryValidation: {
        isLoading: false,
        useLastError: false,
        isFormSubmitting: false,
        bypassNameValidation: false,
        errorMessage: {
          iban: null,
          name: null
        }
      },
      isValid: false,
      accounts: null,
      form: {
        amount: null,
        accountId: null,
        recipientName: '',
        recipientIban: '',
        referenceNumber: '',
        description: ''
      },
      limitError: {
        limitPeriodType: 'DAILY'
      },
      limits: {}
    }
  },
  watch: {
    'form.recipientIban' () {
      if (this.form.referenceNumber.length > 0 && this.form.recipientIban.length > 0) {
        this.$validator.validate('referenceNumber')
      }
    },
    // This is needed to trigger bb-form validation
    'form.referenceNumber' (value) {
      value && this.$validator.validate('description')
    },
    // This is needed to trigger bb-form validation
    'form.description' (value) {
      value && this.$validator.validate('referenceNumber')
    },
    'shouldResetPaymentForm' (value) {
      if (value === true) {
        this.getAccounts()
        this.form = {
          ...this.paymentDetails,
          createdTime: undefined
        }
        this.setShouldResetPaymentForm(false)
      }
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'channel', 'currency', 'featureFlags']),
    ...mapState(usePaymentStore, ['paymentStatus', 'shouldResetPaymentForm', 'paymentDetails']),
    ...mapState(useAccountStore, ['verificationData']),
    maxInputLengths () {
      return {
        reference: PAYMENT_REFERENCE_MAX_LENGTH,
        description: PAYMENT_DESCRIPTION_MAX_LENGTH
      }
    },
    translations () {
      return {
        title: this.$pgettext('payment_form', 'Payments'),
        heading: this.$pgettext('payment_form', 'Outgoing payment'),
        account: this.$pgettext('payment_form', 'From account'),
        recipientName: this.$pgettext('payment_form', 'Recipient name'),
        recipientIban: this.$pgettext('payment_form', 'Recipient IBAN'),
        amount: this.$pgettext('payment_form', 'Amount'),
        description: this.$pgettext('payment_form', 'Description'),
        referenceNumber: this.$pgettext('payment_form', 'Reference number'),
        submit: this.$pgettext('payment_form', 'Send money'),
        availableBalance: this.$pgettext('payment_form', 'Available balance: %'),
        selectAccount: this.$pgettext('payment_form', 'Select account you wish to transfer from'),
        updateLimits: {
          DAILY: this.$pgettext('payment_form', 'Update your daily limit to proceed with this operation'),
          MONTHLY: this.$pgettext('payment_form', 'Update your monthly limit to proceed with this operation'),
          BOTH: this.$pgettext('payment_form', 'Update your daily and monthly limit to proceed with this operation'),
          button: this.$pgettext('payment_form', 'Increase limit')
        },
        verifyAccount: {
          description: this.$gettextInterpolate(this.$pgettext('payment_form', 'In order to make payments you need to identify yourself. Current remaining limit is %{amount}.'), {
            amount: formatMoneyWithCurrency(this.verificationData.availableAmount, this.currency, this.locale)
          }),
          button: this.$pgettext('payment_form', 'Identify')
        },
        errors: {
          descriptionOrReference: this.$pgettext('payment_form', 'Insert payment description or reference'),
          descriptionLength: this.$pgettext('payment_form', 'Description length is too big'),
          referenceInvalid: this.$pgettext('payment_form', 'Reference is invalid'),
          referenceAllowedSymbols: this.$pgettext('payment_form', 'Reference can only contain numbers'),
          amountSize: this.$pgettext('payment_form', 'Amount exceeds available balance'),
          limits: {
            DAILY: this.$pgettext('payment_form', 'Daily limit exceeded'),
            MONTHLY: this.$pgettext('payment_form', 'Monthly limit exceeded'),
            BOTH: this.$pgettext('payment_form', 'Daily and monthly limit exceeded')
          },
          ffLimit: this.$pgettext('payment_form', 'Monthly payoyt limit of €15,000 exceeded'),
          beneficiary: {
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNameMismatch]: this.$pgettext(
              'payment_form',
              'Entered name is incorrect, please review'
            ),
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNonExistingAccount]: this.$gettext(
              'payment_form',
              'Entered IBAN does not exist'
            ),
            INVALID_IBAN: this.$pgettext(
              'payment_form',
              'Entered IBAN is incorrect, please review'
            )
          }
        }
      }
    },
    amountRules () {
      const hasSelectedAccount = !!this.form.accountId

      return {
        required: true,
        ffLimit: true,
        limits: hasSelectedAccount,
        amountSize: hasSelectedAccount ? this.availableBalance : false
      }
    },
    button () {
      return {
        disabled: !this.isValid || this.isLoading || this.isValidationLoading,
        loading: this.isLoading || this.isValidationLoading
      }
    },
    accountOptions () {
      if (!this.accounts) {
        return []
      }

      return this.accounts.map(account => ({
        text: account.iban,
        value: account.id
      }))
    },
    currencySymbol () {
      return currencyCodeToSymbol(this.currency)
    },
    availableBalance () {
      if (!this.form.accountId) {
        return null
      }

      return this.accounts.find(account => account.id === this.form.accountId)?.availableBalance ?? 0
    },
    availableBalanceFormatted () {
      return this.translations.availableBalance.replace('%', formatMoneyWithCurrency(this.availableBalance, this.currency, this.locale))
    },
    hasAmountError () {
      const value = parseFloat(this.form.amount)

      return value > 0 && value > this.availableBalance
    },
    amountErrorCssClasses () {
      return { 'f-color-red': this.hasAmountError }
    },
    isPaymentResultShown () {
      return Object.values(PaymentStatus).includes(this.paymentStatus)
    },
    hasLimitsError () {
      return this.$validator.errors
        .collect('amount', undefined, false)
        .some(error => error.rule === 'limits')
    },
    hasFfLimitError () {
      return this.$validator.errors
        .collect('amount', undefined, false)
        .some(error => error.rule === 'ffLimit')
    },
    isUpdateLimitsButtonVisible () {
      return this.featureFlags.enableAccountsLimitsTab
    },
    isEstonianIban () {
      return (this.form?.recipientIban ?? '').startsWith(CountryChannel.EE)
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'resetPaymentDetails',
      'redirectToPaymentForm',
      'setPaymentStatus',
      'updatePaymentDetails',
      'setShouldResetPaymentForm'
    ]),
    ...mapActions(useAccountStore, ['getVerificationData']),
    maxTwoDecimals,
    onReferenceNumberChanged (value) {
      isString(value) && this.$nextTick(() => {
        this.form.referenceNumber = value.replace(/[^0-9]/g, '').replaceAll(' ', '')
      })
    },
    async onSuccessfulPaymentSigning (signingRequestId) {
      try {
        this.isLoading = true
        const response = await api.completePayment(signingRequestId)
        this.updatePaymentDetails({ createdTime: response.createdTime })
        this.setPaymentStatus(PaymentStatus.Success)
      } catch (_err) {
        this.onPaymentSigningCancelled()
      } finally {
        this.isLoading = false
      }
    },
    onPaymentSigningCancelled () {
      this.setPaymentStatus(PaymentStatus.Failure)
    },
    onProgress (value) {
      this.isValid = value === 1
    },
    async initPaymentSigning () {
      const response = await api.initPayment(this.form)
      this.updatePaymentDetails({ createdTime: response.createdTime })

      return response
    },
    async submit () {
      this.beneficiaryValidation.isFormSubmitting = true
      if (this.isLoading || !await this.$validator.validate() || !await this.validateLimits()) {
        return
      }

      this.isLoading = true
      try {
        this.updatePaymentDetails({ ...this.form })
        this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
      } finally {
        this.beneficiaryValidation.isFormSubmitting = false
        this.isLoading = false
      }
    },
    async getAccounts () {
      this.isLoading = true
      this.accounts = await api.getAccountsForPayment()
      if (this.accounts.length === 1) {
        this.form.accountId = this.accounts[0].id
      }
      this.isLoading = false
    },
    formatIban (inputStr) {
      return inputStr.replace(/\s/g, '').toUpperCase()
    },
    async validateLimits () {
      if (!this.form.accountId || !this.form.amount || this.isValidationLoading) {
        return
      }
      try {
        this.isValidationLoading = true

        const [limits] = await Promise.all([
          api.getAccountLimits(this.form.accountId),
          this.getVerificationData({ forceReload: true })
        ])

        this.limits[this.form.accountId] = limits

        return await this.$validator.validate('amount')
      } finally {
        this.isValidationLoading = false
      }
    },
    async validateBeneficiary (fieldName) {
      if (this.beneficiaryValidation.isFormSubmitting) {
        return true
      }

      try {
        this.isValidationLoading = true

        if (this.beneficiaryValidation.bypassNameValidation) {
          this.beneficiaryValidation.bypassNameValidation = false

          return true
        }

        if (this.beneficiaryValidation.useLastError) {
          this.beneficiaryValidation.useLastError = false
          return false // throw validation error as last result contains error for this field
        }

        const isIbanValid = iban.isValid(this.form.recipientIban)

        if (
          !this.form.recipientName ||
        !this.form.recipientIban ||
        !isIbanValid
        ) {
          return true
        }

        this.beneficiaryValidation.errorMessage.iban = null
        this.beneficiaryValidation.errorMessage.name = null
        this.beneficiaryValidation.isLoading = true

        const result = await api.validateBeneficiary({
          iban: this.form.recipientIban,
          beneficiary: this.form.recipientName
        })

        if (
          result.matched === false &&
          Object.keys(this.translations.errors.beneficiary).includes(result.resultCode)
        ) {
          const resultCodeToFieldMap = {
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNameMismatch]: 'recipientName',
            [BeneficiaryValidationResultCode.BigbankIBANFormatButNonExistingAccount]: 'recipientIban',
            INVALID_IBAN: 'recipientIban'
          }

          const message = this.translations.errors.beneficiary[result.resultCode]
          const errorField = resultCodeToFieldMap[result.resultCode]
          if (errorField === 'recipientName') {
            this.beneficiaryValidation.errorMessage.name = message
          } else {
            this.beneficiaryValidation.errorMessage.iban = message
          }

          if (errorField !== fieldName) {
            // run validation for other field, but use the validation result of this one,
            // in order to not create double requests
            this.beneficiaryValidation.useLastError = true
            await this.$validator.validate(errorField)
          }

          // if error is irrelevant to the fieldName provided, bypass validation
          return errorField !== fieldName
        } else if (isString(result.systemFullName)) {
          this.form.recipientName = result.systemFullName
          // trigger validation again for name field in order to update field status after name is autocompleted
          if (fieldName === 'recipientIban') {
            this.beneficiaryValidation.bypassNameValidation = true
            this.beneficiaryValidation.errorMessage.name = null
            this.$validator.validate('recipientName')
          }
        }

        return true
      } finally {
        this.beneficiaryValidation.isLoading = false
        this.isValidationLoading = false
      }
    },
    onIdentify () {
      this.$router.push({ name: AccountRouteName.AccountVerification })
    },
    onUpdateLimits () {
      this.$router.push({ name: AccountRouteName.AccountsLimits })
    }
  },
  mounted () {
    this.resetPaymentDetails()
    this.redirectToPaymentForm()
  },
  created () {
    this.$validator.extend('atLeastOneFilled', {
      getMessage: () => {
        return this.translations.errors.descriptionOrReference
      },
      validate: (value, [referenceField]) => {
        const otherValue = this.form[referenceField]
        const isValid = (!isNil(value) && value.length > 0) || (!isNil(otherValue) && otherValue.length > 0)

        return {
          valid: isValid,
          data: {
            required: !otherValue
          }
        }
      }
    }, {
      computesRequired: true
    })
    this.$validator.extend('descriptionLength', {
      getMessage: () => this.translations.errors.descriptionLength,
      validate: value => isString(value) && value.length <= PAYMENT_DESCRIPTION_MAX_LENGTH
    })
    this.$validator.extend('referenceCorrectness', {
      getMessage: () => this.translations.errors.referenceInvalid,
      validate: value => {
        if (this.form.description.length > 0 && this.form.referenceNumber.trim().length === 0) {
          return true
        }

        return isValidReferenceNumber(this.channel, value, {
          strict: this.isEstonianIban && this.featureFlags.enablePaymentReferenceNumberStrictValidation
        })
      }
    })
    this.$validator.extend('amountSize', {
      getMessage: () => null,
      validate: (value, [maxAmount]) => {
        return parseFloat(value) > 0 && parseFloat(value) <= maxAmount
      }
    })
    this.$validator.extend('ffLimit', {
      getMessage: () => null,
      validate: () => {
        const paymentAmount = parseFloat(this.form.amount)
        const verificationData = this.verificationData

        if (!verificationData.isF2fIdentified && verificationData.availableAmount < paymentAmount) {
          return false
        }

        return true
      }
    })
    this.$validator.extend('limits', {
      getMessage: () => null,
      validate: () => {
        if (!this.form.accountId) {
          return true
        }

        const paymentAmount = parseFloat(this.form.amount)
        const limits = this.limits[this.form.accountId]

        if (!limits) {
          return true
        }

        const failingLimits = limits.filter(limit => paymentAmount > limit.remainingLimit)

        if (failingLimits.length > 0) {
          this.limitError = {
            limitPeriodType: failingLimits.length > 1 ? 'BOTH' : failingLimits[0].limitPeriodType
          }
        }

        return !failingLimits.length
      }
    })
    this.$validator.extend('iban', {
      getMessage: () => this.translations.errors.beneficiary.INVALID_IBAN,
      validate: (value) => {
        return iban.isValid(value)
      }
    })
    this.$validator.extend('beneficiary-iban', {
      getMessage: () => this.beneficiaryValidation.errorMessage.iban,
      validate: async () => {
        return await this.validateBeneficiary('recipientIban')
      }
    })
    this.$validator.extend('beneficiary-name', {
      getMessage: () => this.beneficiaryValidation.errorMessage.name,
      validate: async () => {
        return await this.validateBeneficiary('recipientName')
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.payments {
  &__main {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @media (min-width: $desktop-view-breaking-point) {
      max-width: 425px;
      min-width: 425px;
      width: auto;
    }
  }

  &__heading {
    color: $gray;
    font-size: $font-size-small;
    font-family: $gotham-bold;
    font-weight: 400;
  }

  &__available-balance {
    margin-top: 2px;
    font-size: $font-size-smallest
  }
}
</style>
