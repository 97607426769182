<template>
  <div>
    <bb-modal
      visible
      v-bind="modalProps"
      @close="onClose"
    >
      <div class="header">{{ translations.modalTitle }}</div>
      <div class="body">
        <bb-icon class="icon" name="illustration-vault" size="fluid" fill="mint-70"/>
        <div class="text">{{ translations.modalText }}</div>
      </div>
      <div slot="footer" class="modal-footer">
        <div class="buttons">
          <bb-button
            inverted
            color="blue"
            :label="translations.No"
            @click="onClose"
          />
          <bb-button
            inverted
            color="blue"
            :label="translations.Yes"
            @click="releaseReservation"
            :loading="signingLoading"
          />
        </div>
      </div>
    </bb-modal>
    <sign-modal
      v-if="showSignModal"
      :deposit="defaultDemandDeposit"
      :chosen-sign-method="chosenSignMethod"
      :signingRequestId="signingRequestId"
      @signingSuccessful="complete"
      @close="closeSignModal"/>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import '@bigbank/interface-components/dist/svg/illustration/vault'
import { DepositRouteName } from '@deposits/const'
import { useRootStore } from '@/store/root'
import { useDepositStore } from '@deposits/store/depositStore'
import { api } from '@deposits/api'
import SignModal from '@deposits/components/SignModal.vue'
import { formatDateForRequest } from '@/plugins/dateFormatters'
import signingMixin from '@/mixins/signingMixin'

export default {
  name: 'outpayment-cancellation-modal',
  components: { SignModal },
  mixins: [signingMixin],
  data () {
    return {
      signingLoading: false,
      showSignModal: false,
      chosenSignMethod: null,
      signingRequestId: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['isMobile']),
    ...mapState(useDepositStore, ['defaultDemandDeposit']),
    translations () {
      return {
        modalTitle: this.$pgettext('cancel_SD_reservation', 'Withdraw cancellation'),
        modalText: this.$pgettext('cancel_SD_reservation', 'Are you sure to cancel withdraw process?'),
        No: this.$pgettext('cancel_SD_reservation', 'No'),
        Yes: this.$pgettext('cancel_SD_reservation', 'Yes')
      }
    },
    modalProps () {
      return {
        flavor: this.isMobile ? 'card' : 'user',
        fullMobile: true,
        centerVertically: !this.isMobile,
        publicSize: 'm'
      }
    }
  },
  methods: {
    complete () {
      this.onClose()
      this.signingRequestId = null
      window.eventBus.$emit('demand-deposit__withdraw')
    },
    onClose () {
      return this.$router.push({ name: DepositRouteName.DemandDeposits })
    },
    closeSignModal () {
      this.showSignModal = false
    },
    async releaseReservation () {
      if (this.signingLoading) {
        return
      }

      this.signingLoading = true

      const { contractVersionId, id: depositId, currentAccountId } = this.defaultDemandDeposit
      const reservedOutpaymentRequest = {
        ids: [Number(this.$route.query.reservationId)],
        effectiveDate: formatDateForRequest(new Date()),
        contractVersionId
      }

      try {
        const initResponse = await api.initReleaseReservedOutpayment(
          depositId,
          currentAccountId,
          reservedOutpaymentRequest
        )
        this.chosenSignMethod = initResponse.method
        this.signingRequestId = initResponse.signingRequestId
        this.showSignModal = initResponse.isSigningNeeded

        !initResponse.isSigningNeeded && this.complete()
      } catch (err) {
        this.closeSignModal()
      } finally {
        this.signingLoading = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.header {
  color: $blue;
  font-weight: 500;
  font-size: $font-size-small;
  font-family: $gotham-medium;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    max-width: 250px;
  }

  .text {
    color: $gray;
    text-align: center;
    font-size: $font-size-default;
    font-family: $gotham-book;
    line-height: 18px;

    @media (max-width: $mobile-view-breaking-point) {
      width: auto;
      max-width: 275px;
    }

    margin-top: 35px;
  }
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  padding: 10px;

  @media (max-width: $mobile-view-breaking-point) {
    padding: 10px;
    flex-direction: column;
    row-gap: 10px;
  }

  div {
    flex: 1 0 0;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  .bb-button {
    min-width: 45%;
  }

  @media (max-width: $mobile-view-breaking-point) {
    padding: 0 1.875rem 1.875rem;
  }

}
</style>
