<template>
    <div :class="{company: isCompany, empty: cardList.length < 1}">
      <corporate-clarification-banner
        v-if="cardList.length > 0 && isCompany"
        product="loan"
      />
      <multi-card
        v-for="(card, index) in cardList"
        :key="card.id"
        :currency="card.currency"
        :card="card"
        :cardType="card.type"
        :index="index"
        :count="cardList.length" />
      <box-with-icon
        v-if="cardList.length < 1"
        icon="client-empty-cards">
      <span v-sanitize.basic="setEmptyBoxTranslations" />
      </box-with-icon>
    </div>
</template>

<script>
import MultiCard from './MultiCard'
import { mapState } from 'pinia'
import CampaignCardsMixin from '../../../mixins/campaignCardsMixin'
import BoxWithIcon from '@/components/BoxWithIcon'
import CARD_TYPE from '@/components/cards/cardTypes'
import { translationPicker } from '@/plugins/translations'
import { InternalLoanType } from '@bigbank/dc-common/enums/productTypes'
import '@bigbank/interface-components/dist/svg/client/empty-cards'
import isUndefined from 'lodash/isUndefined'
import CorporateClarificationBanner from '../../../components/corporate/CorporateClarificationBanner'
import { useRootStore } from '../../../store/root'
import { useLoanStore } from '@loan/store/loanStore'

const STATUS = {
  ACTIVE: 'active',
  CLOSED: 'closed'
}

export default {
  name: 'versatile-cards',
  components: { MultiCard, BoxWithIcon, CorporateClarificationBanner },
  props: {
    status: {
      type: String
    }
  },
  mixins: [
    CampaignCardsMixin
  ],
  data () {
    return {
      closedContractStatuses: [STATUS.CLOSED]
    }
  },
  computed: {
    ...mapState(useRootStore, {
      globalFeatureFlags: 'featureFlags'
    }),
    ...mapState(useRootStore, ['isCompany', 'language', 'isMobile', 'locale', 'channelConfig']),
    ...mapState(useLoanStore, ['applications', 'loans', 'isPromiseToPayShown', 'loanTypes', 'featureFlags']),
    translations () {
      return {
        closedViewMessage: this.$pgettext('deposit_dashboard', 'As soon as you have closed contracts, they will be available here.'),
        emptyViewCorporateMessage: this.$pgettext('deposit_dashboard', 'Message that will tell you that you don\'t have anything to show.')
      }
    },
    setEmptyBoxTranslations () {
      if (this.isCompany && !this.hasActiveContracts && !this.isClosedView) {
        return this.translations.emptyViewCorporateMessage
      }

      return this.translations.closedViewMessage
    },
    contractsByType () {
      const contracts = this.loans.reduce((acc, contract) => {
        const index = this.closedContractStatuses.includes(contract.contractStatus.toLowerCase()) ? STATUS.CLOSED : STATUS.ACTIVE
        contract.type = CARD_TYPE.CONTRACT
        acc[index].push(contract)

        return acc
      }, { active: [], closed: [] })

      return contracts
    },
    applicationsByType () {
      const applications = Array.isArray(this.applications) ? this.applications : []

      return applications
        .filter(({ typeCode }) => typeCode !== this.loanTypes.REVOLVING)
        .filter(({ typeCode }) => !this.featureFlags.enableShowCreditCardApplicationUnderCreditCardView || typeCode !== this.loanTypes.CREDIT_CARD)
        .reduce((acc, application) => {
          const index = this.closedContractStatuses.includes(application.status.toLowerCase()) ? STATUS.CLOSED : STATUS.ACTIVE
          application.type = CARD_TYPE.APPLICATION
          acc[index].push(application)
          return acc
        }, { active: [], closed: [] })
    },
    offers () {
      const features = this.featureFlags || {}
      const offers = []

      if (features.enableOfferCard && this.upsell) {
        const upsellAmount = this.upsell.amount
        const upsellStartUrl = this.upsell.url
        const segmentCode = this.upsell.segment
        const currency = this.upsell.currency
        offers.push({ upsellAmount, segmentCode, type: CARD_TYPE.UPSELL, currency, upsellStartUrl })
      }

      if (this.applicationsByType.active.length + this.contractsByType.active.length + offers.length <= 0 && !this.globalFeatureFlags.disableApplyForALoan) {
        if (!this.isCompany) {
          offers.push({
            type: CARD_TYPE.OFFER,
            title: this.$pgettext('offer_card', 'Great to have you back.'),
            description: this.$pgettext('offer_card', 'You don\'t have any active loan contracts, but please check out our offers.'),
            buttonText: this.$pgettext('offer_card', 'Check Our Offers'),
            to: isUndefined(this.channelConfig.applyLoanUrl) ? '/' : translationPicker(this.channelConfig.applyLoanUrl[InternalLoanType.LOAN], this.language)
          })
        }
      }
      return offers
    },
    cardList () {
      if (this.loading) {
        return []
      }

      const contracts = this.contractsByType
      const applications = this.applicationsByType
      const campaigns = this.campaignsForCards

      if (this.status === STATUS.CLOSED) {
        return applications.closed
          .concat(contracts.closed)
      }

      let list = [
        ...this.offers,
        ...applications.active
      ]

      if (this.isPromiseToPayShown(this.loans)) {
        list.unshift({
          type: CARD_TYPE.PROMISE_TO_PAY,
          promiseToPay: this.promiseToPay
        })
      }

      const showCampaignsAsSecondCard = (list.length || contracts.active.length) && campaigns.length && this.isMobile

      if (showCampaignsAsSecondCard) {
        list = list
          .concat(contracts.active.slice(0, 1))
          .concat(campaigns)
          .concat(contracts.active.slice(1))
      } else {
        list = list
          .concat(campaigns)
          .concat(contracts.active)
      }

      return list
    },
    contractCount () {
      const contracts = this.contractsByType

      return contracts.active.length + contracts.closed.length
    },
    hasActiveContracts () {
      return this.contractsByType.active.length > 0
    },
    isClosedView () {
      return this.status === STATUS.CLOSED
    }
  }
}
</script>
